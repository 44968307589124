@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  border: none;
  margin-top: 30px;
  background: url(/html/template/kaneyoshi/assets/img/common/bg_copyright.png) repeat-x bottom;

  @include media_desktop(){
    padding-top: 40px;
    margin-top: 100px;
  }
  & &__inner{
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-layoutRole__footer {
    background: url(/html/template/kaneyoshi/assets/img/common/bg_title.png) no-repeat top;
}

.ec-footerNavi{
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link{
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    a{
      display: block;
      border: none;
      padding: 15px 0;
      font-size: 14px;
      color: #603813;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

@media only screen and (max-width: 767px) {
.tbl-shipping-cost { 
    display: block; 
    width: 100%; 
    border: none;
  }
  .tbl-shipping-cost thead{ 
    display: block; 
    float: left;
      width: 40%;
    overflow-x:scroll;
  }
  .tbl-shipping-cost tbody{ 
    display: block; 
    width: 60%; 
    overflow-x: auto; 
    white-space: nowrap;
  }
  .tbl-shipping-cost th{ 
    display: block;
    width:auto;
  }
    .tbl-shipping-cost thead tr {
        display: block;
        width: 100%;
    }
  .tbl-shipping-cost tbody tr{ 
    display: inline-block; 
    margin: 0 -3px;
      width: 100%;
  }
  .tbl-shipping-cost td{ 
    display: block;
  }
  .tbl-shipping-cost thead th {
    padding: 6px 2em;
}
    .pref-sp {
        display: none;
    }
}


table.tbl-shipping-cost {
    margin-bottom: 30px;
    border: 1px solid #BBA68D;
}
.tbl-shipping-cost thead tr {
    vertical-align: top;
}
.tbl-shipping-cost thead th {
    width: 100%;
    padding: 6px 0px ;
	border-left:1px dotted #BBA68D;
	border-bottom:1px dotted #BBA68D;
	text-align:center;
	background: #f5deb3;
	font-weight: normal;
}

.tbl-shipping-cost tbody th, .tbl-shipping-cost tbody td {
	padding:6px 2.8rem;
	background-color:#FFF;
	border-left:1px dotted #BBA68D;
	border-bottom:1px dotted #BBA68D;
	text-align:center;
}

h3.guide-content-title {
    font-size: 1.17em;
}
h3.guide-content-title a {
    color: #603813;
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  padding: 40px 0 10px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 50px 0 10px;
  }

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}

.ec-footerRole .ec-footerRole__inner .tori01 img {
    width: 150px;
}