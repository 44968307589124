@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: 16px;
    }
  }
  & &__title {
    .ec-headingTitle {
      font-size: 20px;
      line-height: 1.6;
      background: #fffaf0;
      padding: 1rem;
      border-radius: 6px;
      @include media_desktop {
        font-size: 2rem;
      }
    }
  }
  & &__tags {
    margin-top: 0;
    padding: 0;
    padding-bottom: 0x;
    .ec-productRole__tag.tag_2 {
    color: #fff;
    background-color: #0092C6;
}
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #D7DADD;
    border-radius: 3px;
    background-color: #F5F7F8;
  }
  & &__priceRegular {
    padding-top: 1px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: #DE5D50;
    font-style: italic;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
        .hanbaikakaku {
    font-size: 2rem;
    }
    @include media_desktop {
      padding: 6px 0;
    }
  }
  & &__code {
    padding: 6px 0;
  }
  & &__category {
    padding: 14px 0;
    display: flex;
    padding: 1rem;
    border-radius: 6px;
    a {
      color: #b22222;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    padding: 14px;
    background: rgba(204, 204, 204, 0.1);
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
    #ProductClass label.form-check-label {
    font-weight: normal;
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 10px;
    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px;
    }
  }
  & &__description {
    margin-bottom: 16px;
    background: #fffaf0;
    padding: 1rem;
    line-height: 1.6;
    border-radius: 6px;
    
    dl.clearfix.product {
  width:100%;
    background: #faf0e6;
    border-right: 1px dotted #B28500;
    border-bottom: 1px dotted #B28500;
    border-left: 1px dotted #B28500;
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 0;
}
dl.clearfix.product dt,
dl.clearfix.product dd {
  padding: 5px 5px 0 8px;
  border-top: 1px dotted #B28500;
}
dl.clearfix.product dt {
  width: 20%;
  float: left;
  text-align: center;
  font-weight: normal;
}
dl.clearfix.product dd {
  background: #fff;
  margin-left: 20%;
  padding-bottom: 5px;
  border-left: 1px dotted #B28500;
}
dl.clearfix.product dd:after {
  content: '';
  display: block;
  clear: both;
}
    
  }

}
