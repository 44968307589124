/**
 * ECCUBE 固有のスタイルユーティリティ
 */

@mixin borderTop(){
  border-top: 1px dotted #ccc;
}

@mixin borderBottom(){
  border-bottom: 1px dotted #ccc;
}

@mixin reset_link(){
  a{
    color: #42210B;
    text-decoration: none;
    font-weight: normal;
  }
  a:hover{
    text-decoration: none;
  }
}
